import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1080.000000 935.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,935.000000) scale(0.100000,-0.100000)">
     

     <path d="M2 4678 l-2 -4678 5403 2 5402 3 -5397 2 -5398 3 -3 4673 -2 4672 -3
-4677z"/>
<path d="M6961 7073 c-20 -4 -22 -9 -17 -35 3 -17 6 -34 6 -38 0 -4 56 -5 125
-2 111 4 132 2 197 -19 61 -19 82 -32 128 -78 36 -36 62 -74 74 -106 24 -65
28 -68 65 -60 38 8 39 25 7 99 -46 105 -117 172 -227 217 -45 18 -78 22 -194
24 -77 2 -151 1 -164 -2z"/>
<path d="M6972 6903 c-19 -4 -23 -9 -18 -26 3 -12 6 -29 6 -38 0 -14 11 -16
88 -11 65 3 103 0 146 -12 72 -21 139 -80 159 -139 15 -45 39 -57 76 -37 20
10 20 12 4 53 -56 143 -183 219 -363 216 -41 -1 -86 -4 -98 -6z"/>
<path d="M4300 6675 c0 -147 -15 -134 160 -138 l115 -2 3 -420 c1 -279 -1
-438 -9 -474 -22 -109 -113 -159 -261 -145 -85 8 -123 31 -151 92 -19 41 -22
69 -25 222 -2 99 -8 180 -14 188 -8 9 -63 12 -214 12 l-204 0 1 -167 c0 -256
32 -343 175 -490 114 -117 204 -144 479 -145 186 -1 316 17 388 53 54 28 189
164 217 219 43 85 44 93 49 580 5 369 9 475 19 476 6 1 64 2 127 3 l115 1 0
124 c0 104 -3 125 -16 130 -9 3 -227 6 -485 6 l-469 0 0 -125z"/>
<path d="M6982 6733 c-31 -4 -33 -6 -30 -41 l3 -37 75 -1 c41 0 92 -6 112 -13
41 -13 85 -59 94 -97 6 -24 33 -31 68 -18 55 21 -56 170 -143 194 -47 12 -137
19 -179 13z"/>
<path d="M5939 6403 c-6 -42 -5 -212 2 -229 8 -23 13 -24 141 -26 l133 -3 0
-445 0 -445 -25 -50 c-36 -72 -93 -99 -209 -99 -121 -1 -158 18 -190 99 -22
53 -25 80 -29 233 -4 165 -5 172 -26 177 -11 3 -106 4 -211 3 l-190 -3 -3 -95
c-4 -126 13 -287 37 -349 27 -71 78 -145 152 -220 111 -111 194 -134 484 -134
272 1 353 22 469 124 76 67 124 134 148 207 21 64 22 88 27 532 l6 465 128 3
127 3 0 117 c0 79 -4 122 -12 130 -9 9 -130 12 -485 12 -260 0 -474 -3 -474
-7z"/>
<path d="M5385 6381 c-96 -44 -128 -127 -85 -216 14 -28 19 -50 13 -54 -56
-37 -105 -98 -123 -154 -18 -57 2 -127 48 -172 77 -75 238 -87 345 -27 l56 31
48 -27 c41 -22 63 -27 145 -30 68 -3 98 -1 98 7 0 6 -8 11 -18 11 -26 0 -130
54 -167 85 -27 24 -28 28 -15 48 36 56 59 115 60 153 0 49 -8 54 -88 53 -50 0
-54 -2 -30 -10 15 -6 30 -19 33 -31 12 -35 -16 -148 -37 -148 -14 0 -177 160
-225 222 -78 102 -91 169 -40 216 67 63 187 11 187 -80 0 -45 19 -2 25 58 l7
61 -32 12 c-51 17 -159 13 -205 -8z m84 -432 c66 -67 118 -125 116 -129 -3 -5
-25 -16 -50 -26 -55 -21 -135 -15 -173 13 -36 25 -72 98 -72 143 0 39 33 120
49 120 5 0 64 -55 130 -121z"/>
<path d="M1040 4350 c0 -7 1460 -10 4360 -10 2900 0 4360 3 4360 10 0 7 -1460
10 -4360 10 -2900 0 -4360 -3 -4360 -10z"/>
<path d="M2637 4138 c-78 -197 -139 -347 -147 -363 -8 -17 -57 -140 -187 -467
-41 -104 -68 -133 -129 -143 -28 -4 -44 -11 -41 -18 3 -9 52 -12 171 -13 120
-1 166 1 163 10 -3 10 -22 18 -76 32 -19 6 -8 55 41 186 l41 108 154 0 c114 0
155 -3 158 -12 2 -7 25 -71 51 -141 42 -112 46 -131 35 -149 -21 -33 0 -38
172 -38 131 0 157 2 157 15 0 9 -14 16 -37 20 -97 16 -105 30 -258 420 -200
513 -223 565 -246 565 -9 0 -19 -6 -22 -12z m54 -431 c31 -84 54 -155 52 -159
-2 -5 -56 -8 -119 -8 -94 0 -114 3 -114 15 0 12 53 160 107 301 3 8 8 12 12 9
3 -4 31 -75 62 -158z"/>
<path d="M4317 4143 c-3 -5 -32 -75 -65 -158 -96 -243 -203 -507 -222 -550
-10 -22 -27 -66 -39 -98 -49 -133 -73 -162 -144 -172 -37 -6 -45 -10 -37 -20
8 -10 50 -13 171 -12 160 2 202 13 120 31 -36 8 -41 12 -41 36 0 22 42 148 85
253 6 15 24 17 159 17 l152 0 53 -145 c52 -139 53 -146 36 -159 -18 -13 -18
-14 3 -25 30 -16 324 -11 329 6 3 7 -13 14 -42 18 -25 4 -56 16 -69 26 -33 26
-56 79 -238 534 -88 220 -165 406 -170 413 -12 13 -34 16 -41 5z m52 -435 c29
-81 50 -152 46 -158 -7 -12 -221 -14 -228 -2 -2 4 22 77 54 162 32 85 58 157
59 160 1 18 25 -36 69 -162z"/>
<path d="M6492 4138 c-6 -6 -12 -17 -12 -23 0 -6 -16 -49 -35 -95 -20 -47 -99
-243 -176 -437 -152 -379 -171 -413 -230 -413 -19 0 -41 -5 -49 -10 -31 -21
14 -28 171 -27 124 0 159 3 159 14 0 7 -17 15 -40 19 -37 6 -40 9 -40 38 1 17
20 83 44 146 l44 115 156 3 c116 2 156 -1 156 -10 0 -6 23 -72 50 -146 48
-126 50 -135 33 -147 -17 -13 -16 -14 10 -24 35 -14 314 -15 322 -2 8 14 -28
31 -62 31 -54 0 -84 46 -173 270 -21 52 -70 176 -109 275 -178 446 -184 458
-219 423z m43 -398 c21 -58 44 -122 51 -143 8 -21 12 -43 9 -48 -7 -11 -222
-12 -228 -1 -4 5 49 158 107 309 10 24 15 14 61 -117z"/>
<path d="M3076 4076 c-20 -81 -21 -126 -2 -126 7 0 19 14 26 31 23 53 46 61
191 63 l131 1 -1 -413 c-2 -278 -6 -421 -13 -438 -8 -18 -19 -24 -42 -24 -41
0 -64 -14 -45 -26 18 -11 408 -14 415 -3 8 13 -32 29 -74 29 -36 0 -41 3 -51
31 -7 21 -11 170 -11 442 l0 410 118 -5 c141 -6 176 -19 188 -73 15 -63 29
-39 32 57 l3 93 -393 0 c-216 0 -407 4 -424 8 l-31 8 -17 -65z"/>
<path d="M8861 4122 c-11 -22 -31 -115 -31 -147 0 -37 27 -31 44 8 20 49 56
59 199 61 l122 1 0 -421 c0 -325 -3 -424 -13 -436 -7 -9 -30 -18 -52 -22 -22
-3 -40 -11 -40 -16 0 -13 -17 -12 213 -15 108 -1 200 1 202 6 9 13 -31 29 -73
29 -28 0 -42 5 -48 18 -5 9 -10 207 -12 440 l-3 422 84 0 c159 0 227 -26 227
-85 0 -13 6 -25 14 -28 18 -7 26 33 26 122 l0 66 -392 0 c-216 0 -408 3 -425
7 -26 6 -35 4 -42 -10z"/>
<path d="M1080 4111 c0 -6 18 -11 40 -11 27 0 47 -7 61 -21 20 -20 20 -27 17
-448 -2 -327 -6 -432 -16 -443 -7 -9 -30 -18 -52 -22 -22 -3 -40 -11 -40 -16
0 -13 -13 -12 185 -18 395 -13 475 -6 605 55 68 32 168 131 204 201 63 124 76
299 31 419 -58 154 -153 239 -319 286 -63 18 -107 21 -393 25 -226 2 -323 0
-323 -7z m541 -65 c121 -26 209 -84 269 -178 57 -87 74 -165 67 -301 -9 -199
-67 -291 -218 -348 -68 -26 -208 -36 -277 -20 -91 22 -87 -1 -90 444 -4 366
-3 392 14 404 24 17 150 17 235 -1z"/>
<path d="M5125 4110 c3 -5 20 -10 36 -10 51 0 85 -25 113 -85 15 -30 96 -225
181 -432 173 -423 194 -468 215 -468 16 0 39 42 99 180 128 297 303 692 328
741 18 35 60 64 92 64 12 0 21 5 21 10 0 6 -60 10 -161 10 -104 0 -158 -3
-154 -10 3 -5 21 -10 40 -10 40 0 51 -22 35 -66 -34 -96 -256 -644 -261 -644
-6 0 -22 40 -148 376 -106 281 -112 302 -86 321 11 7 29 13 42 13 13 0 23 5
23 10 0 6 -77 10 -211 10 -137 0 -208 -3 -204 -10z"/>
<path d="M7004 4111 c3 -4 26 -11 52 -14 25 -2 51 -10 57 -16 9 -8 14 -112 18
-344 6 -356 9 -380 64 -467 91 -145 340 -201 528 -121 26 11 71 44 100 73 97
95 117 190 117 568 0 163 4 258 11 274 12 24 35 36 77 36 12 0 22 5 22 10 0 6
-65 10 -175 10 -96 0 -175 -4 -175 -9 0 -4 20 -11 45 -14 26 -2 49 -7 52 -10
15 -16 22 -113 23 -327 0 -259 -11 -384 -40 -439 -41 -81 -125 -131 -219 -131
-71 0 -119 18 -166 61 -76 70 -78 86 -83 474 -3 339 -3 340 18 357 12 10 35
18 51 18 16 0 29 5 29 10 0 6 -75 10 -206 10 -113 0 -203 -4 -200 -9z"/>
<path d="M8180 4110 c0 -5 18 -10 39 -10 22 0 46 -7 55 -15 24 -25 28 -105 24
-491 -5 -415 -6 -421 -67 -426 -24 -2 -36 -8 -36 -18 0 -12 46 -15 317 -17
298 -3 318 -2 327 15 12 22 27 138 23 170 -6 37 -26 25 -42 -26 -23 -71 -54
-84 -198 -80 -131 3 -137 7 -147 92 -3 28 -5 210 -3 404 l3 354 24 19 c13 10
38 19 57 19 19 0 34 5 34 10 0 6 -75 10 -205 10 -130 0 -205 -4 -205 -10z"/>
<path d="M1040 2980 c0 -7 1460 -10 4360 -10 2900 0 4360 3 4360 10 0 7 -1460
10 -4360 10 -2900 0 -4360 -3 -4360 -10z"/>
</g>
</svg>


    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
